import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index.js'

// keepAlive放在store中修改
const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/extract'
  },
  {
    path: '/register',
    name: 'register',
    meta: {},
    component: () => import('@/views/register.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {},
    component: () => import('../views/login.vue')
  },
  // 绑定手机号
  {
    path: '/binding',
    name: 'binding',
    meta: {},
    component: () => import('../views/bindingPhone/index.vue')
  },
  // 新手教程
  {
    path: '/course',
    name: 'course',
    meta: { title: '新手教程' },
    component: () => import('@/views/course/index.vue')
  },
  // 全部新手教程页面
  {
    path: '/courseAll',
    name: 'courseAll',
    meta: {},
    component: () => import('@/views/course/all.vue')
  },
  // 生成海报页面
  {
    path: '/poster',
    name: 'poster',
    meta: { title: '推广海报' },
    component: () => import('../views/poster/index.vue')
  },
  // 文案库文案
  {
    path: '/scriptTask',
    name: 'scriptTask',
    component: () => import('../views/script/taskIndex.vue'),
    meta: { auth: true }
  },
  // 我的推广文案
  {
    path: '/scriptExtension',
    name: 'scriptExtension',
    component: () => import('../views/script/extensionIndex.vue'),
    meta: { auth: true }
  },
  // 联系我们
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/information/contact.vue'),
    meta: {}
  },
  // 福利页面
  {
    path: '/welfare1',
    name: 'welfare1',
    component: () => import('../views/welfare/welfare1.vue'),
    meta: {}
  },
  {
    path: '/welfare2',
    name: 'welfare2',
    component: () => import('../views/welfare/welfare2.vue'),
    meta: {}
  },
  // 用户信息
  {
    path: '/userAbout',
    name: 'userAbout',
    component: () => import('../views/information/userAbout.vue'),
    meta: { auth: true }
  },
  // 渠道绑定
  {
    path: '/bindingChannel',
    name: 'bindingChannel',
    component: () => import('../views/information/bindingChannel.vue'),
    meta: { auth: true }
  },
  // 渠道绑定的绑定账号页面
  {
    path: '/addAccount',
    name: 'addAccount',
    component: () => import('../views/information/addAccount.vue'),
    meta: { auth: true }
  },
  // 提现记录
  {
    path: '/withdrawalRecord',
    name: 'withdrawalRecord',
    component: () => import('../views/withdrawalRecord/index.vue'),
    meta: { auth: true }
  },
  // 完善收款信息
  {
    path: '/withdrawal',
    name: 'withdrawal',
    component: () => import('../views/profit/withdrawal.vue'),
    meta: { auth: true, title: '完善信息' }
  },
  // 文案库
  // {
  //   path: '/task',
  //   name: 'task',
  //   component: () => import('../views/task'),
  //   meta: {}
  // },
  // 文案提取
  {
    path: '/extract',
    name: 'extract',
    component: () => import('../views/extract'),
    meta: { auth: true }
  },
  // 我的推广
  {
    path: '/extension',
    name: 'extension',
    component: () => import('../views/extension/index.vue'),
    meta: { auth: true }
  },
  // 我的推广——申请关键词
  // {
  //   path: '/applyWord',
  //   name: 'applyWord',
  //   component: () => import('../views/extension/childPage/applyWord.vue'),
  //   meta: { auth: true }
  // },
  // 我的推广——编辑关键词
  {
    path: '/editWord',
    name: 'editWord',
    component: () => import('../views/extension/childPage/editWord.vue'),
    meta: { auth: true }
  },
  // 收益数据
  {
    path: '/profit',
    name: 'profit',
    component: () => import('../views/profit/index.vue'),
    meta: { auth: true }
  },
  // 个人中心
  {
    path: '/information',
    name: 'information',
    component: () => import('../views/information'),
    meta: {
      auth: true
    }
  },
  // 回传列表
  {
    path: '/backList',
    name: 'backList',
    component: () => import('../views/backList'),
    meta: {
      auth: true
    }
  },
  // 回传信息
  {
    path: '/backInfo',
    name: 'backInfo',
    component: () => import('../views/backInfo'),
    meta: {
      auth: true
    }
  },
  {
    path: '/task',//匹配未定义的路由
    redirect: '/extract'//重定向
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { left: 0, top: 0 }
  }
})
import { wxLoginGetCode } from '@/api/login.js'
router.beforeEach((to, from, next) => {
  let token = store.state.user.token
  // 验证当前路由所有的匹配中是否需要有登录验证的
  if (to.matched.some(r => r.meta.auth)) {
    if (token && token !== 'undefined') {
      next()
    } else {
      // 没有登录的时候跳转到登录界面
      // 携带上登陆成功之后需要跳转的页面完整路径<---现在固定进首页
      // if (store.state.user.platform == 'wx') {
      //   wxLoginGetCode().then(res => {
      //     window.location.href = res
      //   })
      // } else {
      next({
        name: 'login',
        query: {
          redirect: to.fullPath
        }
      })
      // }
    }
  } else {
    // 登录之后没有退出机制,也就不允许从新登录和注册
    let noNext = ['/login', '/register']
    if (!noNext.includes(to.path)) {
      // 不需要身份校验 直接通过
      next()
    } else {
      if (!token || token == 'undefined') {
        next()
      } else {
        next('/information')
      }
    }
  }
})
router.afterEach((to, from) => {
  window.document.title = to.meta.title ? to.meta.title : '海文达人助手'
})

export default router
