import { request } from './index'

/**
 * 获取验证码
 * @param {*} data sendChannel:reg 注册 login 登录
 * @returns
 */
export function sendVerificationCode(data) {
  return request({
    method: 'post',
    url: '/user/sendVerificationCode',
    data
  })
}

/**
 * 注册
 * @param {*} data
 * @returns
 */
export function register(data) {
  return request({
    method: 'post',
    url: '/user/verificationCodeReg',
    data
  })
}
/**
 * 登录
 * @param {*} data  phone 手机号 verificationCode验证码
 * @returns
 */
export function login(data) {
  return request({
    method: 'post',
    url: '/user/verificationCodeLogin',
    data
  })
}

/**
 * 微信登录——获取code
 */
export function wxLoginGetCode(data) {
  return request({
    method: 'post',
    url: '/user/WechatCodeUrl',
    data
  })
}

/**
 * 微信登录
 */
export function wxLogin(data) {
  return request({
    method: 'post',
    url: '/user/WechatLogin',
    data
  })
}

/**
 * 微信登录绑定手机号
 */
export function bindingPhone(data) {
  return request({
    method: 'post',
    url: '/user/WechatLoginBind',
    data
  })
}

/**
 * 微信登录注册手机号
 */
export function newBindingPhone(data) {
  return request({
    method: 'post',
    url: '/user/WechatBind',
    data
  })
}
