import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import layout from './module/layout'
import keepAlive from './module/keepAlive'
import user from './module/user.js'
export default createStore({
  plugins: [createPersistedState()],
  modules: {
    layout,
    keepAlive,
    user
  }
})
