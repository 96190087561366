/**
 *
 * @param {*} val 处理平台类型
 * 平台类型 10 公众号 20客户端 30 快应用
 */
let platformList = [
  { id: 10, name: '公众号' },
  { id: 20, name: 'APP' },
  { id: 30, name: '快应用' }
]
function platform_type_filter(val) {
  if (val) {
    let res = platformList.find(item => {
      return item.id == val
    })
    return res.name || '未知'
  } else {
    return '未知'
  }
}
function setDateFormat(val) {
  // IOS new Date() 中的时间不能用(1990-05-06 12:00) 这种格式.否则会报错(不能用 '-')
  if (val) {
    /*  let D = new Date(val)
    return `${D.getFullYear()}-${
      D.getMonth() + 1
    }-${D.getDate()} ${D.getHours()}:${D.getMinutes()}` */
    return val.substr(0, 11)
  }
}
/**
 * 处理数字三位加一逗号
 */
function setNumFormat(val) {
  return Number(val).toLocaleString()
}
// 处理金额，以分为单位
function setMoneyFormat(num) {
  if (num) {
    num = (num / 100).toFixed(2) //保留两位小数
    var str = num.toString()
    var reg =
      str.indexOf('.') > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g
    return str.replace(reg, '$1,')
  } else {
    return 0
  }
}

export default {
  setDateFormat,
  platform_type_filter,
  setNumFormat,
  setMoneyFormat
}
