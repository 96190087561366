import router from '@/router/index'
import axios from 'axios'
import { Toast } from 'vant'
import Cookies from 'js-cookie'
import store from '../store/index.js'

function createService() {
  // 创建一个axios 实例
  const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API
  })
  // 请求拦截
  service.interceptors.request.use(
    config => {
      // let token = window.localStorage.getItem('token') || Cookies.get('token')
      let token = store.state.user.token

      // return config  没有 retrun config 就会报错
      config.headers.Authorization = token
      return config
    },
    error => {
      // 发送失败
      return Promise.reject(error)
    }
  )

  service.interceptors.response.use(
    response => {
      let { errCode, errMsg, data } = response.data
      if (errCode === 0) {
        return data
      } else if (errCode == 4005) {
        throw errMsg
      } else {
        // 4001是没有token 4005是使用没有绑定手机号的微信登录
        if (errCode == 4001) {
          store.dispatch('setToken', {})
          store.commit('setList', [])
          router.push('/login')
        }
        Toast.fail(errMsg)
       /*  Toast.loading({
          message: errMsg,
          duration: 1000000,
          forbidClick: true
        }) */
        throw errMsg
      }
    },
    error => {
      const status = error.response.status
      switch (status) {
        case 400:
          error.message = '请求错误'
          break
        case 401:
          error.message = '未授权，请登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          error.message = '服务器内部错误'
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '网关错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网关超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
          break
      }
      Toast('网络错误,请稍后重试')
      return Promise.reject(error)
    }
  )
  return service
}
export const request = createService()
