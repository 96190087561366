const keepAlive = {
  state() {
    return {
      list: [
        'task',
        'bindingChannel',
        'information',
        'userAbout',
        'contact',
        'extension'
      ],
      // 更新文案库已推广状态
      updateTaskId: ''
    }
  },
  mutations: {
    setList(state, val) {
      state.list = val
    },
    add(state, name) {
      console.log(1111)
      state.list.indexOf(name) < 0 && state.list.push(name)
    },
    remove(state, name) {
      state.list = state.list.filter(v => {
        return v != name
      })
    },
    clean(state) {
      state.list = []
    },
    setTaskId(state, id) {
      state.updateTaskId = id
    }
  }
}
export default keepAlive
