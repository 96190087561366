const layout = {
  state() {
    return {
      // 搜索框的值及筛选项的值
      searchVal: {},
      // 控制tabBar显示隐藏
      hiddenTabBarFlag: true,
      // 控制空页面的显示隐藏
      controlEmptyFlag: false,
    }
  },
  mutations: {
    SET_SEARCH_VAL(state, val) {
      state.searchVal = val
    },
    SET_TABBAR_DISPLAY(state, val) {
      state.hiddenTabBarFlag = val
    },
    SET_EMPTY_FLAG(state, val) {
      state.controlEmptyFlag = val
    },
    SET_RECORD_SCROLL(state, val) {
      state.scrollTop = val
    },
  },
  actions: {
    setSearchVal({ commit }, val) {
      commit('SET_SEARCH_VAL', val)
    },
    setTabBarDisplay({ commit }, val) {
      commit('SET_TABBAR_DISPLAY', val)
    },
    setEmptyFlag({ commit }, val) {
      commit('SET_EMPTY_FLAG', val)
    }
  }
}

export default layout
