const keepAlive = {
  state() {
    return {
      // 判断是wx环境还是浏览器环境
      platform: '',
      token: '',
      wechat: ''
    }
  },
  mutations: {
    SET_TOKEN(state, val) {
      state.token = val.token
      // wechat后加的，0是手机号，1是没绑手机号
      state.wechat = val.wechat
    },
    SET_PLATFORM(state, val) {
      state.platform = val
    }
  },
  actions: {
    setToken({ commit }, val) {
      commit('SET_TOKEN', val)
    },
    setPlatform({ commit }, val) {
      commit('SET_PLATFORM', val)
    }
  }
}
export default keepAlive
