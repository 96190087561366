import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/style/reset.css'
import './assets/icon/iconfont.css'
// 复制内容到粘贴板
import VueClipboard from 'vue-clipboard2'
import utils from './utils/index.js'
// 引入vant中的组件
import {
  Button,
  Form,
  Field,
  Toast,
  List,
  Collapse,
  CollapseItem,
  Lazyload,
  Divider,
  Tab,
  Tabs,
  DropdownMenu,
  DropdownItem,
  Empty,
  Overlay,
  SwipeCell,
  Slider,
  RadioGroup,
  Radio,
  ActionSheet,
  Checkbox,
  CheckboxGroup,
  Area,
  Popup,
  Picker,
  Icon,
  Search,
  Swipe,
  SwipeItem,
  ImagePreview,
  Grid,
  GridItem,
  Calendar,
  Cell,
  CellGroup,
  Sticky,
  Uploader
} from 'vant'
const app = createApp(App)
app.use(VueClipboard)
app
  .use(Form)
  .use(Field)
  .use(CellGroup)
  .use(Button)
  .use(Toast)
  .use(List)
  .use(Collapse)
  .use(CollapseItem)
  .use(Lazyload)
  .use(Divider)
  .use(Tab)
  .use(Tabs)
  .use(DropdownMenu)
  .use(DropdownItem)
  .use(Empty)
  .use(Overlay)
  .use(SwipeCell)
  .use(Slider)
  .use(RadioGroup)
  .use(Radio)
  .use(ActionSheet)
  .use(Checkbox)
  .use(CheckboxGroup)
  .use(Area)
  .use(Popup)
  .use(Picker)
  .use(Icon)
  .use(Search)
  .use(Swipe)
  .use(SwipeItem)
  .use(ImagePreview)
  .use(Grid)
  .use(GridItem)
  .use(Calendar)
  .use(Cell)
  .use(Sticky)
  .use(Uploader)
app.use(store).use(router).mount('#app')
app.config.globalProperties.$utils = utils
app.config.globalProperties.$baseUrl = process.env.VUE_APP_BASE_API + 'tutorial'
