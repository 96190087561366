<template>
  <router-view v-slot="{ Component, route }">
    <keep-alive :include="$store.state.keepAlive.list">
      <component
        :is="Component"
        :key="route.meta.usePathKey ? route.path : undefined"
      />
    </keep-alive>
    <!--  <keep-alive>
      <component v-if="$route.meta.keepAlive" :is="Component" :key="route.meta.usePathKey ? route.path : undefined" />
    </keep-alive>
    <component v-if="!$route.meta.keepAlive" :is="Component" :key="route.meta.usePathKey ? route.path : undefined" /> -->
  </router-view>
</template>
<script>
import { wxLogin } from '@/api/login.js'

export default {
  name: 'App',
  computed: {
    platform() {
      return this.$store.state.user.platform
    },
    token() {
      return this.$store.state.user.token
    }
  },
  async created() {
    // 设置平台
    var ua = navigator.userAgent.toLowerCase()
    this.$store.dispatch(
      'setPlatform',
      ua.match(/MicroMessenger/i) == 'micromessenger' ? 'wx' : 'web'
    )
    //微信授权回来是可以重新触发mounted的
    if (this.platform == 'wx' && !this.token) {
      let code = this.getQueryString('code')
      let state = this.getQueryString('state')
      if (code != null && state != null) {
        let res = await wxLogin({ code })
        this.$store.commit('setList', [
          'task',
          'bindingChannel',
          'information',
          'userAbout',
          'contact',
          'extension'
        ])
        this.$store.dispatch('setToken', {
          token: res.token,
          wechat: res.wechat
        })
      }
    }
  },
  methods: {
    getQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    }
  }
}
</script>

<style lang="less">
html,
body {
  height: 100%;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #333333;
  background-color: rgb(247, 247, 247);
  user-select: none;
  // min-height: 100vh;
  height: 100%;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
